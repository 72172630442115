// Generated by Framer (1a6990e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useConstant, useIsOnFramerCanvas, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["g9ZlDFQ7e", "BJ7JqejsJ"];

const serializationHash = "framer-BpnK8"

const variantClassNames = {BJ7JqejsJ: "framer-v-1u26wtc", g9ZlDFQ7e: "framer-v-10m0bff"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Closed: "g9ZlDFQ7e", Open: "BJ7JqejsJ"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, JnTstBR4r: tap ?? props.JnTstBR4r, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "g9ZlDFQ7e"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, JnTstBR4r, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "g9ZlDFQ7e", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap9gfkqd = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (JnTstBR4r) {
const res = await JnTstBR4r(...args);
if (res === false) return false;
}
})

const isOnCanvas = useIsOnFramerCanvas()

const initialVariant = useConstant(() => variant)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-10m0bff", className, classNames)} data-framer-name={"Closed"} data-highlight initial={isOnCanvas ? variant : initialVariant } layoutDependency={layoutDependency} layoutId={"g9ZlDFQ7e"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap9gfkqd} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({BJ7JqejsJ: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1roy5y7"} layoutDependency={layoutDependency} layoutId={"DgN94UZAa"} style={{backgroundColor: "var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(0, 0, 0))", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, rotate: 0}} variants={{BJ7JqejsJ: {rotate: 45}}}/><motion.div className={"framer-rwy2o5"} layoutDependency={layoutDependency} layoutId={"rNcgltOvN"} style={{backgroundColor: "var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(0, 0, 0))", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, rotate: 0}} variants={{BJ7JqejsJ: {rotate: -45}}}/><motion.div className={"framer-ojntsp"} layoutDependency={layoutDependency} layoutId={"bcblipZ0O"} style={{backgroundColor: "var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(0, 0, 0))", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, rotate: 0}} variants={{BJ7JqejsJ: {rotate: -45}}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-BpnK8[data-border=\"true\"]::after, .framer-BpnK8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BpnK8.framer-1g64elo, .framer-BpnK8 .framer-1g64elo { display: block; }", ".framer-BpnK8.framer-10m0bff { cursor: pointer; height: 43px; overflow: visible; position: relative; width: 40px; }", ".framer-BpnK8 .framer-1roy5y7 { flex: none; height: 1px; left: calc(50.00000000000002% - 28px / 2); overflow: visible; position: absolute; top: 12px; width: 28px; }", ".framer-BpnK8 .framer-rwy2o5 { flex: none; height: 1px; left: calc(50.00000000000002% - 28px / 2); overflow: visible; position: absolute; top: calc(48.837209302325604% - 1px / 2); width: 28px; }", ".framer-BpnK8 .framer-ojntsp { bottom: 12px; flex: none; height: 1px; left: calc(50.00000000000002% - 28px / 2); overflow: visible; position: absolute; width: 28px; }", ".framer-BpnK8.framer-v-1u26wtc .framer-1roy5y7 { top: 21px; }", ".framer-BpnK8.framer-v-1u26wtc .framer-ojntsp { bottom: 21px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 43
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"BJ7JqejsJ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"JnTstBR4r":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerjFRRVE0eX: React.ComponentType<Props> = withCSS(Component, css, "framer-BpnK8") as typeof Component;
export default FramerjFRRVE0eX;

FramerjFRRVE0eX.displayName = "Menu Button";

FramerjFRRVE0eX.defaultProps = {height: 43, width: 40};

addPropertyControls(FramerjFRRVE0eX, {variant: {options: ["g9ZlDFQ7e", "BJ7JqejsJ"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, JnTstBR4r: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerjFRRVE0eX, [])